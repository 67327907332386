import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IFirm } from "../../interfaces/models";
import {
  fetchFirmByIdAPI,
  fetchFirmsAPI,
  searchFirmsAPI,
  updateFirmRatingAPI,
} from "./firmApi";

export interface CounterState {
  firmByIdLoading: boolean;
  firmById: IFirm | null;

  firmsLoading: boolean;
  firms: IFirm[];
  firmsTotalCount: number;

  firsmSearchLoading: boolean;
  firmSearchResults: IFirm[];
}

const initialState: CounterState = {
  firmById: null,
  firmByIdLoading: false,

  firmsLoading: false,
  firms: [],
  firmsTotalCount: 0,
  firsmSearchLoading: false,
  firmSearchResults: [],
};

export const fetchFirmById = createAsyncThunk(
  "firm/fetchFirmById",
  async (id: string) => {
    const response = await fetchFirmByIdAPI(id);
    return response.data;
  }
);

export const fetchFirms = createAsyncThunk(
  "firm/fetchFirms",
  async ({ page, perPage }: { page: number; perPage: number }) => {
    const response = await fetchFirmsAPI({ page, perPage });
    return response.data;
  }
);

export const searchFirms = createAsyncThunk(
  "firm/searchFirms",
  async ({ search }: { search: string }) => {
    const response = await searchFirmsAPI({ search });
    return response.data;
  }
);

export const updateFirmRating = createAsyncThunk(
  "firm/updateFirmRating",
  async ({ id, ...rest }: any) => {
    const response = await updateFirmRatingAPI(id, rest);
    return response.data;
  }
);

export const firmSlice = createSlice({
  name: "firm",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchFirmById.pending, (state) => {
        state.firmByIdLoading = true;
      })
      .addCase(fetchFirmById.fulfilled, (state, action) => {
        state.firmByIdLoading = false;
        state.firmById = action.payload;
      })
      .addCase(fetchFirmById.rejected, (state) => {
        state.firmByIdLoading = false;
      })
      .addCase(fetchFirms.pending, (state) => {
        state.firmsLoading = true;
      })
      .addCase(fetchFirms.fulfilled, (state, action) => {
        state.firmsLoading = false;
        state.firms = action.payload.results;
        state.firmsTotalCount = action.payload.totalCount;
      })
      .addCase(fetchFirms.rejected, (state) => {
        state.firmsLoading = false;
      })
      .addCase(searchFirms.pending, (state) => {
        state.firsmSearchLoading = true;
      })
      .addCase(searchFirms.fulfilled, (state, action) => {
        state.firsmSearchLoading = false;
        state.firmSearchResults = action.payload;
      })
      .addCase(searchFirms.rejected, (state) => {
        state.firsmSearchLoading = false;
      });
  },
});

export const {} = firmSlice.actions;

export default firmSlice.reducer;
