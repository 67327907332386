import axios from "axios";
import { store } from "../store";
const AUTH_HEADER_NAME = "Authorization";

const request = axios.create({
  baseURL: process.env.REACT_APP_PUBLIC_API_URL,
});
// service.interceptors.request.use(async (config) => {
//   // config.
//   const token = store.getState().auth.token;

//   if (token && !config.headers[AUTH_HEADER_NAME]) {
//     config.headers[AUTH_HEADER_NAME] = `bearer ${token}`;
//   }

//   return config;
// });

// service.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   (error) => {
//     if (error.response.status === 401) {
//       localStorage.clear();
//       const user = store.getState().auth.user;
//       if (user) {
//         localStorage.setItem(
//           `latest_location_${user.UserId}_${user.Role}`,
//           window.location.href
//         );
//       }

//       store.dispatch({ type: "auth/logoutUser", payload: undefined });
//     } else {
//       throw error;
//     }
//   }
// );
request.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.status === 403) {
      let lastLocationKey = "location";
      try {
        const user = JSON.parse(window.localStorage.getItem("user") as string);
        if (user) {
          lastLocationKey = `location-${user.id}`;
        }
      } catch (e) {}
      window.localStorage.setItem(lastLocationKey, window.location.href);
      window.localStorage.removeItem("user");
      window.localStorage.removeItem("token");
      window.location.href = "/auth/login";
      return;
    }
    console.log(error);
    throw new Error(error.response?.data?.message || "Something went wrong");
  }
);

export default request;
