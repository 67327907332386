import { IUser } from "../../interfaces/models";
import request from "../../utils/request";

export function requestEmailVerifyAPI(data: any) {
  return request.post<{ token: string; pinLength: number, pin?: string }>(
    `/auth/verify-email/request`,
    data
  );
}

export function submitEmailVerifyAPI(data: any) {
  return request.post<boolean>(`/auth/verify-email/submit`, data);
}

export function registerUserAPI(data: any) {
  return request.post<{ user: IUser; token: string }>(`/auth/register`, data);
}

export function loginUserAPI(data: any) {
  return request.post<{ user: IUser; token: string }>(`/auth/login`, data);
}

export function resetPasswordAPI(data: any) {
  return request.post<{ user: IUser; token: string }>(`/auth/reset-password`, data);
}
