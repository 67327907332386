import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ISurvey } from "../../interfaces/models";
import { fetchSurveyByIdAPI } from "./surveyApi";

export interface CounterState {
  surveyByIdLoading: boolean;
  surveyById: ISurvey | null;
}

const initialState: CounterState = {
  surveyById: null,
  surveyByIdLoading: false,
};

export const fetchSurveyById = createAsyncThunk(
  "survey/fetchSurveyById",
  async (id: string) => {
    const response = await fetchSurveyByIdAPI(id);
    return response.data;
  }
);

export const surveySlice = createSlice({
  name: "survey",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSurveyById.pending, (state) => {
        state.surveyByIdLoading = true;
      })
      .addCase(fetchSurveyById.fulfilled, (state, action) => {
        state.surveyByIdLoading = false;
        state.surveyById = action.payload;
      })
      .addCase(fetchSurveyById.rejected, (state) => {
        state.surveyByIdLoading = false;
      });
  },
});

export const {} = surveySlice.actions;

export default surveySlice.reducer;
