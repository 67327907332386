import { Col, Layout, Row } from "antd";

interface Props {
  children: React.ReactNode | React.ReactNode[];
}

const AuthLayout = ({ children }: Props) => {
  return (
    <Layout>
      <Layout.Content style={{ margin: 0 }}>
        <Row>
          <Col xs={24} md={14}>
            <div style={{ padding: "5% 10%", height: "100vh" }}>
              <img src="/assets/images/text_logo.png" alt="Logo" />
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  height: "100%",
                }}
              >
                {children}
              </div>
            </div>
          </Col>
          <Col xs={0} md={10}>
            <div
              style={{
                background: "url(/assets/images/auth_sider.png)",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                width: "100%",
                height: "100vh",
              }}
            />
          </Col>
        </Row>
      </Layout.Content>
    </Layout>
  );
};

export default AuthLayout;
