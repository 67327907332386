import { ConfigProvider } from "antd";
import { ReactNode } from "react";

const MainTheme = ({ children }: { children: ReactNode | ReactNode[] }) => (
  <ConfigProvider
    theme={{
      token: {
        colorPrimary: "rgba(0, 0, 0, 0.05)",
        colorBgContainer: "#ffffff",
      },
    }}
  >
    {children}
  </ConfigProvider>
);

export default MainTheme;
