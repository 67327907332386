import React, { Suspense } from "react";
import { createBrowserRouter, RouterProvider, Outlet } from "react-router-dom";
import "antd/dist/reset.css";
import "./App.css";
import "./theme/scss/index.scss";
import MainTheme from "./theme/MainTheme";
import DashboardLayout from "./layouts/DashboardLayout";
import { ROUTES } from "./app/routes";
import AuthLayout from "./layouts/AuthLayout";

// Auth
const SignUp = React.lazy(() => import("./pages/auth/signup"));
const Login = React.lazy(() => import("./pages/auth/login"));
const SignupVerifyEmail = React.lazy(
  () => import("./pages/auth/signup/verify-email")
);
const SignupPassword = React.lazy(() => import("./pages/auth/signup/password"));
const Confirm = React.lazy(() => import("./pages/auth/confirm"));
const ResetPassword = React.lazy(() => import("./pages/auth/reset"));
const ResetPasswordVerifyEmail = React.lazy(
  () => import("./pages/auth/reset/verify-email")
);
const ResetPasswordPassword = React.lazy(
  () => import("./pages/auth/reset/password")
);
const ResetPasswordConfirm = React.lazy(
  () => import("./pages/auth/reset/confirm")
);

const Home = React.lazy(() => import("./pages"));
const Recommendations = React.lazy(() => import("./pages/recommendations"));
const ListDetails = React.lazy(() => import("./pages/lists/[id]"));
const FundDetails = React.lazy(() => import("./pages/funds/[id]"));
const FundSearch = React.lazy(() => import("./pages/funds/search"));
const RatingSystem = React.lazy(() => import("./pages/rating-system"));
const SurveyDetails = React.lazy(() => import("./pages/survey/[id]"));
const Search = React.lazy(() => import("./pages/search"));

function DashboardRoot() {
  return (
    <DashboardLayout>
      <Suspense fallback={<div>Loading</div>}>
        <Outlet />
      </Suspense>
    </DashboardLayout>
  );
}

function AuthRoot() {
  return (
    <AuthLayout>
      <Suspense fallback={<div>Loading</div>}>
        <Outlet />
      </Suspense>
    </AuthLayout>
  );
}

const router = createBrowserRouter([
  {
    path: "/auth",
    element: <AuthRoot />,
    children: [
      {
        path: ROUTES.auth.signup(),
        element: <SignUp />,
      },
      {
        path: ROUTES.auth.login(),
        element: <Login />,
      },
      {
        path: ROUTES.auth.reset.index(),
        element: <ResetPassword />,
      },
      {
        path: ROUTES.auth.reset.verifyEmail(),
        element: <ResetPasswordVerifyEmail />,
      },
      {
        path: ROUTES.auth.reset.password(),
        element: <ResetPasswordPassword />,
      },
      {
        path: ROUTES.auth.reset.confirm(),
        element: <ResetPasswordConfirm />,
      },
      {
        path: ROUTES.auth.signUpVerifyEmail(),
        element: <SignupVerifyEmail />,
      },
      {
        path: ROUTES.auth.signUpPassword(),
        element: <SignupPassword />,
      },
      {
        path: ROUTES.auth.confirm(),
        element: <Confirm />,
      },
    ],
  },
  {
    path: "/",
    element: <DashboardRoot />,
    children: [
      {
        path: ROUTES.home(),
        element: <Home />,
      },
      {
        path: ROUTES.search(),
        element: <Search />,
      },
      {
        path: ROUTES.recommendations(),
        element: <Recommendations />,
      },
      {
        path: ROUTES.lists.details(),
        element: <ListDetails />,
      },
      {
        path: ROUTES.funds.search(),
        element: <FundSearch />,
      },
      {
        path: ROUTES.funds.details(),
        element: <FundDetails />,
      },
      {
        path: ROUTES.ratingSystem.main(),
        element: <RatingSystem />,
      },
      {
        path: ROUTES.survey.details(),
        element: <SurveyDetails />,
      },
      {
        path: "*",
        element: <div>Not Found</div>,
      },
    ],
  },
]);

function App() {
  return (
    <div className="App">
      <MainTheme>
        <RouterProvider router={router} />
      </MainTheme>
    </div>
  );
}

export default App;
