import qs from "querystring";
import { IFirm } from "../../interfaces/models";
import request from "../../utils/request";

export function fetchFirmByIdAPI(id: string) {
  return request.get<IFirm>(`/firm/${id}`);
}

export function fetchFirmsAPI(params: any) {
  return request.get<{ results: IFirm[]; totalCount: number }>(
    `/firm?${qs.encode(params)}`
  );
}

export function searchFirmsAPI(params: any) {
  return request.get<IFirm[]>(`/firm/search?${qs.encode(params)}`);
}

export function updateFirmRatingAPI(id: string, data: any) {
  return request.put<IFirm>(
    `/firm/${id}/rating`,
    data
  );
}
