import React, { useCallback, useEffect, useMemo, useState } from "react";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import {
  Button,
  Dropdown,
  Input,
  MenuProps,
  Space,
  Tooltip,
  Typography,
} from "antd";
import { Breadcrumb, Layout, Menu, theme } from "antd";
import "./style.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useIsBreakpointDown from "../../app/hooks/usIsBreakpointDown";
import LogoutButton from "../../components/auth/LogoutButton";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { logoutUser } from "../../app/features/auth/authSlice";
import { ROUTES } from "../../app/routes";

const { Header, Content } = Layout;

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: "group"
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

const items: MenuProps["items"] = [
  getItem(
    "My Dashboard",
    "my-dashboard-group",
    null,
    [
      getItem("Home", "", <img src="/assets/icons/home.svg" alt="Back" />),
      getItem(
        "My Recommendations",
        "recommendations",
        <img src="/assets/icons/thumbs-up.svg" alt="Recommendations" />
      ),
      getItem(
        "My Lists",
        "lists",
        <img src="/assets/icons/lists.svg" alt="Lists" />,
        [
          getItem("Food And Beverages", "food-and-beverages"),
          getItem("Healthcare", "healthcare"),
        ]
      ),
    ],
    "group"
  ),
  getItem("Rating System", "rating-system"),
  getItem(
    "Search For",
    "explore-group",
    null,
    [
      getItem("Companies", "search-companies"),
      getItem("Funds", "funds", null, [
        getItem("Recently Closed Funds", "search"),
      ]),
    ],
    "group"
  ),
  getItem(
    "Explore",
    "explore",
    null,
    [
      getItem(
        "Search Funds",
        "search-funds",
        <img src="/assets/icons/funds-search.svg" alt="Search Funds" />
      ),
    ],
    "group"
  ),
];

const breadcrumbs = [
  {
    id: 1,
    title: "Dashboard",
  },
  {
    id: 2,
    title: "Recommendations",
  },
];

const gridTypeOptions = [
  {
    key: "0",
    label: <img src="/assets/icons/sidebar.svg" alt="Sidebar-Icon" />,
  },
];

interface Props {
  children: React.ReactNode | React.ReactNode[];
}

const DashboardLayout = ({ children }: Props) => {
  const [collapsed, setCollapsed] = useState(false);
  const [selectedGridTypeKey, setSelectedGridTypeKey] = useState(
    gridTypeOptions[0].key
  );

  const { pageTitle, breadcrumbs } = useAppSelector((state) => state.ui);
  const { user } = useAppSelector((state) => state.auth);
  const isSmDown = useIsBreakpointDown("sm");
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const onBack = () => navigate(-1);

  const onLogout = useCallback(() => {
    dispatch(logoutUser());
    navigate(ROUTES.auth.login());
  }, [dispatch, navigate]);

  const selectedGridType = useMemo(() => {
    return gridTypeOptions.find((item) => item.key === selectedGridTypeKey);
  }, [selectedGridTypeKey]);

  useEffect(() => {
    if (isSmDown) {
      setCollapsed(true);
    }
  }, [isSmDown]);

  useEffect(() => {
    if (!user) {
      onLogout();
    }
  }, [user, onLogout]);

  return (
    <Layout
      style={{
        backgroundColor: colorBgContainer,
        flexDirection: "row",
      }}
    >
      {pageTitle ? <title>{pageTitle}</title> : null}
      <div
        style={{
          position: "relative",
          background: colorBgContainer,
          borderRight: "1px solid rgba(0, 0, 0, 0.1)",
        }}
      >
        <Button
          block
          style={{ marginBottom: 16 }}
          onClick={() => setCollapsed(!collapsed)}
        >
          {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        </Button>
        <div
          style={{
            height: 32,
            margin: 16,
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography
            style={{
              fontWeight: "bold",
              textAlign: collapsed ? "center" : "left",
            }}
          >
            <img
              src="/assets/images/person.png"
              alt="Profile"
              style={{ marginRight: 5 }}
            />
            {collapsed ? <br /> : null}
            {user?.firstName || ""} {user?.lastName || ""}
          </Typography>
        </div>
        <Menu
          inlineCollapsed={collapsed}
          theme="light"
          defaultSelectedKeys={["1"]}
          mode="inline"
          expandIcon={(expanded) => (
            <img
              src="/assets/icons/chevron-right.svg"
              style={expanded.isOpen ? { transform: "rotate(-90deg)" } : {}}
              alt="Expand"
            />
          )}
          items={items}
          onClick={(info) =>
            navigate(`/${info.keyPath.slice().reverse().join("/")}`)
          }
        />
        {!collapsed && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "absolute",
              bottom: "3rem",
              left: 0,
              right: 0,
            }}
          >
            <img src="/assets/images/text_logo.png" alt="Logo" />
          </div>
        )}
      </div>
      <Layout
        className="site-layout"
        style={{
          background: colorBgContainer,
          height: "100vh",
          overflowY: "hidden",
          overflowX: "hidden",
        }}
      >
        <Header
          style={{
            background: colorBgContainer,
            borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "0 2rem",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            {!isSmDown && (
              <div style={{ marginRight: "2rem" }}>
                <Dropdown
                  menu={{
                    selectable: true,
                    defaultActiveFirst: true,
                    items: gridTypeOptions,
                    onSelect: (info) => setSelectedGridTypeKey(info.key),
                  }}
                  trigger={["click"]}
                >
                  <Space>
                    {selectedGridType?.label}
                    <img
                      style={{ marginLeft: 10 }}
                      src="/assets/icons/chevron-down-bold.svg"
                      alt="Chevron Down"
                    />
                  </Space>
                </Dropdown>
              </div>
            )}
            <Breadcrumb>
              <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
              {breadcrumbs.map((item) => (
                <Breadcrumb.Item key={`breadcrumb-header-${item.title}`}>
                  {item.title}
                </Breadcrumb.Item>
              ))}
            </Breadcrumb>
          </div>
          {!isSmDown && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              {location.pathname !== ROUTES.search() ? (
                <Input
                  placeholder="Search"
                  prefix={
                    <img
                      src="/assets/icons/search-light.svg"
                      alt="Search Icon"
                    />
                  }
                  suffix={<img src="/assets/icons/c-light.svg" alt="C" />}
                  style={{ width: 200, marginTop: 5, marginRight: 30 }}
                />
              ) : null}
              <div>
                {location.pathname !== ROUTES.home() ? (
                  <>
                    <Button
                      onClick={onBack}
                      type="ghost"
                      icon={
                        <img src="/assets/icons/back-arrow.svg" alt="Back" />
                      }
                    />
                    <Link to={ROUTES.home()}>
                      <Button
                        type="ghost"
                        icon={<img src="/assets/icons/home.svg" alt="Back" />}
                      />
                    </Link>
                  </>
                ) : null}
                <LogoutButton onLogout={onLogout} />
                {/* <Button
                  type="ghost"
                  icon={<img src="/assets/icons/settings.svg" alt="Back" />}
                /> */}
              </div>
            </div>
          )}
        </Header>
        <Content
          style={{
            margin: 0,
            padding: "0 16px",
            background: colorBgContainer,
            height: "90vh",
            overflowY: "scroll",
            overflowX: "hidden",
          }}
        >
          <div
            style={{
              padding: isSmDown ? "24px 0 0 0" : 24,
              minHeight: 360,
              background: colorBgContainer,
            }}
          >
            {children}
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default DashboardLayout;
