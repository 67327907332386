export const ROUTES = {
  auth: {
    signup: () => `/auth/signup`,
    signUpVerifyEmail: () => `/auth/signup/verify-email`,
    signUpPassword: () => `/auth/signup/password`,
    login: () => `/auth/login`,
    reset: {
      index: () => `/auth/reset`,
      verifyEmail: () => `/auth/reset/verify-email`,
      password: () => `/auth/reset/password`,
      confirm: () => `/auth/reset/confirm`,
    },
    verifyEmail: () => `/auth/verify-email`,
    password: () => `/auth/password`,
    confirm: () => `/auth/confirm`,
  },
  home: () => `/`,
  recommendations: () => `/recommendations`,
  lists: {
    details: (id: string = ":id") => `/lists/${id}`,
  },
  funds: {
    search: () => `/funds/search`,
    details: (id: string = ":id") => `/funds/${id}`,
  },
  search: () => `/search-funds`,
  ratingSystem: {
    main: () => "/rating-system",
  },
  survey: {
    details: (id: string = ":id") => `survey/${id}`,
  },
};
