import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import { IUser } from "../../interfaces/models";
import { AppThunk } from "../../store";

interface Breadcrumb {
  title: string;
  url?: string;
}

export interface State {
  pageTitle: string;
  breadcrumbs: Breadcrumb[];
}

const initialState: State = {
  pageTitle: "",
  breadcrumbs: [],
};

export const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    setPageTitle(state, action: PayloadAction<string>) {
      state.pageTitle = action.payload;
      window.document.title = `FundSpectra: ${action.payload}`;
    },
    setBreadcrumbs(state, action: PayloadAction<Breadcrumb[]>) {
      state.breadcrumbs = action.payload;
    },
  },
});

export const { setPageTitle, setBreadcrumbs } = uiSlice.actions;

export default uiSlice.reducer;
