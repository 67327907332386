import { Button, Popover, Tooltip, Typography } from "antd";
import { useState } from "react";
import Card from "../Card";

interface Props {
  onLogout?: () => any;
}

const LogoutButton = ({ onLogout }: Props) => {
  const [open, setOpen] = useState(false);

  const onOpen = () => setOpen(true);
  const onClose = () => setOpen(false);

  const content = (
    <Card
      style={{
        width: 350,
        padding: 10,
        height: "auto",
        textAlign: "center",
        backgroundColor: "white",
      }}
    >
      <div style={{ width: "80%", margin: "0 auto" }}>
        <Typography.Title level={2}>Sign Out</Typography.Title>
        <Typography.Paragraph>
          Are you sure you'd like to sign out?
        </Typography.Paragraph>
        <Button type="primary" block size="large" onClick={onLogout}>
          Log Out
        </Button>
        <Button
          type="link"
          style={{ color: "#000000" }}
          size="large"
          onClick={onClose}
        >
          <span style={{ textDecoration: "underline" }}>Cancel</span>
        </Button>
      </div>
    </Card>
  );

  return (
    <Popover
      placement="bottomLeft"
      trigger="click"
      content={content}
      open={open}
      onOpenChange={(visible) => {
        if (!visible && open) {
          onClose();
        }
      }}
    >
      <Button
        onClick={onOpen}
        type="ghost"
        icon={<img src="/assets/icons/logout.svg" alt="Back" />}
      />
    </Popover>
  );
};

export default LogoutButton;
