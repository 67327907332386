import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import { IUser } from "../../interfaces/models";
import { AppThunk } from "../../store";
import {
  loginUserAPI,
  registerUserAPI,
  requestEmailVerifyAPI,
  resetPasswordAPI,
  submitEmailVerifyAPI,
} from "./authApi";

interface RegisterData {
  name: string;
  email: string;
  pin: string;
}
export interface State {
  loading: boolean;
  user: IUser | null;
  pinLength: number;
  token: string;
  verifyEmailToken: string;
  registerData: RegisterData;
}

const token = window.localStorage.getItem("token") || "";
const userData = window.localStorage.getItem("user");
let user: IUser | null = null;

if (userData) {
  try {
    user = JSON.parse(userData);
  } catch (err) {
    console.log(err);
  }
}

const initialState: State = {
  user: user,
  pinLength: 4,
  loading: false,
  token: token,
  verifyEmailToken: "",
  registerData: {
    name: "",
    email: "",
    pin: "",
  },
};

export const requestEmailVerify =
  (data: { email: string; type: string }): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const response = await requestEmailVerifyAPI(data);
      dispatch(requestEmailVerifySuccess(response.data));
      return response.data;
    } catch (err) {
      dispatch(setLoading(false));
      throw err;
    }
  };

export const submitEmailVerify =
  (data: { pin: string; token: string }): AppThunk =>
  async (dispatch, getState) => {
    dispatch(setLoading(true));
    try {
      const response = await submitEmailVerifyAPI(data);
      const { registerData } = getState().auth;
      dispatch(
        setRegisterData({
          ...registerData,
          pin: data.pin,
        })
      );
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      dispatch(setLoading(false));
      throw err;
    }
  };

export const registerUser =
  (data: {
    pin: string;
    token: string;
    password: string;
    firstName: string;
    lastName: string;
  }): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const response = await registerUserAPI(data);
      dispatch(setLoading(false));
      dispatch(loginSuccess(response.data));
      return response.data;
    } catch (err) {
      dispatch(setLoading(false));
      throw err;
    }
  };

export const loginUser =
  (data: { email: string; password: string }): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const response = await loginUserAPI(data);
      dispatch(setLoading(false));
      dispatch(loginSuccess(response.data));
      return response.data;
    } catch (err) {
      dispatch(setLoading(false));
      throw err;
    }
  };

export const resetPassword =
  (data: { pin: string; token: string; password: string }): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const response = await resetPasswordAPI(data);
      dispatch(setLoading(false));
      dispatch(loginSuccess(response.data));
      return response.data;
    } catch (err) {
      dispatch(setLoading(false));
      throw err;
    }
  };

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setRegisterData: (state, action: PayloadAction<RegisterData>) => {
      state.registerData = action.payload;
    },
    requestEmailVerifySuccess: (state, { payload }) => {
      state.loading = false;
      state.verifyEmailToken = payload.token;
      state.pinLength = payload.pinLength;
    },
    loginSuccess: (
      state,
      { payload }: PayloadAction<{ user: IUser; token: string }>
    ) => {
      state.user = payload.user;
      state.token = payload.token;
      window.localStorage.setItem("token", payload.token);
      window.localStorage.setItem("user", JSON.stringify(payload.user));
    },
    logoutUser: (state) => {
      window.localStorage.removeItem("token");
      window.localStorage.removeItem("user");

      state.user = null;
      state.token = "";
    },
  },
});

export const {
  setLoading,
  loginSuccess,
  setRegisterData,
  requestEmailVerifySuccess,
  logoutUser,
} = authSlice.actions;

export default authSlice.reducer;
