import { Breakpoint, Grid } from "antd";
import { useMemo } from "react";

const breakpoints = ["xs", "sm", "md", "lg", "xl", "xxl"];

export default function useIsBreakpointDown(
  breakpoint: "xs" | "sm" | "md" | "lg"
) {
  const screens = Grid.useBreakpoint();

  return useMemo(() => {
    const activeBreakpoint =
      Object.keys(screens)
        .filter((screen) => screens[screen as Breakpoint])
        .pop() || "";
    if (!activeBreakpoint) return false;

    return (
      breakpoints.indexOf(activeBreakpoint) <= breakpoints.indexOf(breakpoint)
    );
  }, [screens, breakpoint]);
}
