import { Typography } from "antd";
import { ReactNode } from "react";
import "./style.scss";

interface Props {
  children: ReactNode | ReactNode[];
  color?: string;
  className?: string;
  title?: any;
  description?: any;
  style?: any;
}

const Card = ({
  children,
  title,
  description,
  color = "#F7F9FB",
  className,
  style = {},
  ...props
}: Props) => {
  return (
    <div
      style={{ backgroundColor: color, ...style }}
      className={`card ${className}`}
      {...props}
    >
      {title && (
        <Typography.Title level={5} className="title">
          {title}
        </Typography.Title>
      )}
      {description && (
        <Typography.Text className="description">
          {description}
        </Typography.Text>

      )}
      {children}
    </div>
  );
};

export default Card;
